<template>
  <div>
    <div v-if="!isLoading">

      <div v-if="hasLocalSettingsAvailable">
        <!-- Preferences -->
        <b-card>
          <div class="d-flex flex-wrap justify-content-between mt-2 mb-2">
            <h4>{{ $t("backoffice.members.preferences") }}</h4>
            <div>
              <b-button
                variant="primary"
                type="submit"
                :disabled="isSaving"
                class="ml-1"
                @click="handleSaveNetworking"
              >
                {{ $t("form.actions.save") }}
              </b-button>
            </div>
          </div>
          <b-row>
            <!-- Accepts Chat messages -->
            <b-col v-if="isChatAddonInstalled">
              <div class="d-flex flex-wrap">
                <b-form-checkbox v-model="openChat" switch class="mb-1 ml-50" />
                <label for="published" class="font-weight-bold mt-25">
                  {{ $t("backoffice.members.open-chat") }}
                </label>
              </div>
            </b-col>

            <!-- Accepts meeting invitations -->
            <b-col v-if="isMeetingsAddonInstalled">
              <div class="d-flex flex-wrap">
                <b-form-checkbox
                  v-model="openMeetings"
                  switch
                  class="mb-1 ml-50"
                />
                <label for="published" class="font-weight-bold mt-25">
                  {{ $t("backoffice.members.open-meetings") }}</label>
              </div>
            </b-col>

            <!-- Show member in Members list -->
            <b-col v-if="isMembersAddonInstalled">
              <div class="d-flex flex-wrap">
                <b-form-checkbox v-model="showMe" switch class="mb-1 ml-50" />
                <label for="published" class="font-weight-bold mt-25">
                  {{ $t("backoffice.members.show-me") }}</label>
              </div>
            </b-col>
            <!-- Missing back end -->
            <!-- Show Email -->
            <!-- <b-col>
              <div class="d-flex flex-wrap">
                <b-form-checkbox v-model="showEmail" switch class="mb-1 ml-50" />
                <label for="published" class="font-weight-bold mt-25">
                  {{ $t("backoffice.members.show-email") }}</label
                >
              </div>
            </b-col> -->
          </b-row>
        </b-card>

        <!-- Classifiers -->
        <Classifiers
          v-if="hasClassifiers"
          :organization="profile"
          morph-type="member"
          :morph-key="loggedMember.key"
          :classifiers="classifiersData"
          model-type="member"
        />

        <!-- Custom fields -->
        <CustomFields 
          v-if="hasCustomFields" 
          :item="profile" 
          morph-type="member" 
        />
      </div>
      <b-card v-else>
        <h3 class="mb-1">
          {{ $t('backoffice.dashboard.no-data') }}
        </h3>
      </b-card>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { VBTooltip } from 'bootstrap-vue';
import Service from '@/config/service-identifiers';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import { required, max } from '@validations';
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  name: 'LocalSettings',

  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    Classifiers: () => import('@/views/organization-settings/components/Classifiers.vue' /* webpackChunkName: "Classifiers" */),
    CustomFields: () => import('@/views/organization-settings/components/CustomFields.vue' /* webpackChunkName: "CustomFields" */),
  },

  directives: { BTooltip: VBTooltip },
  mixins: [ToastNotificationsMixin],

  data() {
    return {
      member: null,
      required,
      max,
      isStaff: false,
      send: false,
      profile: {
        openMeetings: true,
        openChat: true,
        showMe: true,
        showEmail: true,
      },
      isSaving: false,
      isLoading: true,
      openMeetings: true,
      openChat: true,
      showMe: true,
      memberType: {},
      types: [],
    };
  },

  setup() {
    const { getValidationState } = formValidation(() => {});
    return {
      getValidationState,
    };
  },
  
  computed: {
    ...mapGetters([
      'loggedMember',
      'parentCollective',
      'currentCollective',
      'customFields',
      'classifiers',
      'apps',
    ]),

    communitySlug() {
      return this.$route.params.communityId;
    },
    hasCustomFields() {
      return this.customFields?.length > 0;
    },
    classifiersUnpaginated() {
      return this.classifiers?.community?.unpaginated;
    },
    hasClassifiers() {
      return this.classifiersUnpaginated?.length > 0;
    },
    classifiersData() {
      if (this.classifiers.member) {
        return this.classifiers.member.unpaginated;
      }
      return [];
    },
    classifiersTree() {
      if (this.$store.getters.classifiersTree.members) {
        return this.$store.getters.classifiersTree.members.unpaginated;
      }
      return [];
    },
    installedApps() {
      return this.$store.getters.installedApps;
    },
    isMembersAddonInstalled() {
      return this.apps?.apps[50]?.isActive;
    },
    isChatAddonInstalled() {
      return this.isMembersAddonInstalled && this.apps?.apps[60]?.isActive;
    },
    isMeetingsAddonInstalled() {
      return this.isMembersAddonInstalled && this.apps?.apps[43]?.isActive;
    },
    hasLocalSettingsAvailable() {
      return this.isMembersAddonInstalled || this.hasClassifiers || this.hasCustomFields;
    }
  },
  watch: {
    member(value) {
      return value;
    },
  },
  async created() {
    this.isLoading = true;
    this.$emit('isLoading', this.isLoading);
    await this.fetchMember(this.loggedMember.username);
    this.resetForm();
    await this.fetchMemberClassifiers();
    this.fetchCommunityClassifiers();
    this.fetchCustomFields();
    await this.getInstalledApps();

    // Fetch classifiers tree
    if(this.classifiersTree.length <= 0){
      // await this.fetchClassifiers();
      await this.fetchClassifiersTree(true);
      console.log('this.classifiersTree', this.classifiersTree);
    }

    this.isLoading = false;
    this.$emit('isLoading', this.isLoading);
  },
  methods: {
    async fetchCustomFields() {
      if (!this.customFields) {
        await this.$store.dispatch('fetchCustomFields', { 
          modelType: 'member' 
        });
      }
    },
    async getLoggedMember() {
      // await this.installedMainApps();
      const response = await this.$store.$service[Service.BackendClient].get(
        '/communityMembers',
        {
          params: {
            communitySlug:
              this.$store.getters.mainCollective?.slug
              || this.communitySlug,
            username: this.loggedMember.username,
            isForProfile: true,
          },
        },
      );
      this.mainOrganizations = response.data.data[0]?.organizations;
      this.profile.organizations = this.mainOrganizations?.map((item) => item);
    },
    async getInstalledApps() {
      if (!this.$store.getters.installedApps) {
        await this.$store.dispatch('getInstalledApps');
      }
    },
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    resetForm() {
      this.openChat = this.profile.networking?.chat === 1
        ? true
        : this.profile.networking?.chat !== 0;

      this.openMeetings = this.profile.networking?.meetings === 1
        ? true
        : this.profile.networking?.meetings !== 0;

      this.showMe = this.profile.visibility === 1
        ? true
        : this.profile.visibility !== 0;
    },
    async fetchMember(username) {
      try {
        const spaceSlug = this.currentCollective.slug ?? this.$store.getters.mainCollective?.slug;
        // console.log('SpaceSlug:', spaceSlug);

        // TODO: use member store
        let response = await this.$store.$service[Service.BackendClient].get(
          '/communityMembers',
          {
            params: {
              communitySlug: spaceSlug,
              username,
              isForProfile: true,
            },
          },
        );
        // console.log('response1: ', response);
        // console.log('response.data.data[0]', response.data.data[0])

        const member = response.data.data[0];
        if (!member) {
          this.$router.push({
            name: 'error',
            params: {
              communityId: this.$route.params.communityId,
              error: '404',
            },
          });
          return;
        }
        this.member = member;
      } catch (error) {
        this.hasErrors = true;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('error-message.load-failed'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    async fetchCommunityClassifiers() {
      if (this.classifiers?.community?.unpaginated?.length) {
        return
      }

      await this.$store.dispatch('getItems', {
        storedKey: 'community',
        itemType: 'backoffice/classifiers',
        customName: 'classifiers',
        forceAPICall: true,
        requestConfig: {
          modelType: 'member',
          count: 1000,
          offset: 0,
        },
      });
      // this.classifiersPair();
    },

    async fetchClassifiersTree(force = false) {
      // console.log('fetching classifiersTree');
      await this.$store.dispatch("getItems", {
        itemType: "classifiersTree",
        storedKey: 'members',
        forceAPICall: force,
        requestConfig: {
          modelType: 'member',
          morphType: 'member',
          count: 1000,
        },
      });
    },
    async updateProfile() {
      const requestConfig = {
        communityKey: this.currentCollective.key,
        memberKey: this.member.key,
        visibility: this.profile.showMe == true ? 1 : 0,
        networking: {
          chat: this.openChat == true ? 1 : 0,
          meetings: this.openMeetings == true ? 1 : 0,
        },
      };
      let response = await this.$store.$service[Service.BackendClient].put(
        'members',
        requestConfig,
      );
    },
    async fetchMemberClassifiers() {
      await this.$store.dispatch('getItems', {
        itemType: 'classifiers',
        storedKey: 'member',
        page: 1,
        forceAPICall: true,
        requestConfig: {
          memberKey: this.member?.key,
          count: 1000,
        },
      });
    },
    async onSubmit() {
      try {
        await this.updateProfile();
        this.notifySuccess(this.$t('members.edit.success-message'));
        await this.fetchMember(this.loggedMember.username);
      } catch (error) {
        this.notifyError(this.$t('error-message.general-error'));
      }
    },
    handleSaveNetworking() {
      this.profile.openMeetings = this.openMeetings;
      this.profile.openChat = this.openChat;
      this.profile.showMe = this.showMe;
      this.onSubmit();
    },
    async fetchTypes() {
      const response = await this.$store.$service[Service.BackendClient].get(
        'types',
        {
          params: {
            communitySlug: this.currentCollective.slug,
            getCommunityTypes: 1,
            count: 1000,
            modelType: 'member',
          },
        },
      );
      this.types = response.data.data;
    },
  },
};
</script>
<style lang="scss">
@import "~@core/scss/base/bootstrap-include"; // Bootstrap includes
.foto {
  max-height: 150px;
  max-width: 150px;
}
.banner {
  max-height: 200px;
  max-width: 500px;
}
.settings-form {
  @include media-breakpoint-up(md) {
    width: 75%;
  }
  @include media-breakpoint-up(lg) {
    width: 50%;
  }
  .avatar {
    width: 80px !important;
    height: 80px !important;
  }
}
.field.has-value:not(.no-label) .field-input[data-v-5b500588] {
  // Class of VueCtkDateTimePicker
  color: $dark;
}
.text-edit {
  height: 5rem !important;
}
.close-icon {
  z-index: 9;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: -10px;
}
</style>
